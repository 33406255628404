import {
  Box,
  Grid,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  CopyAllOutlined,
  HeadsetMicOutlined,
  LockOutlined,
  LogoutOutlined,
  NavigateNextOutlined,
  ShieldOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import ServiceModal from "../../components/ServiceModal";
import BindAddress from "../../components/BindAddress";
import ChangePassword from "../../components/ChangePassword";

import { LOGOUT, MESSAGE_OPEN } from "../../redux/types";

import axios from "axios";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 10,
  backgroundSize: "1rem 1rem",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#ff9500",
    backgroundSize: "1rem 1rem",
    backgroundImage:
      "linear-gradient(45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent)",
    ...theme.applyStyles("dark", {
      backgroundColor: "#ff9500",
      backgroundSize: "1rem 1rem",
      backgroundImage:
        "linear-gradient(45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent)",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#ff9500",
    backgroundSize: "1rem 1rem",
    backgroundImage:
      "linear-gradient(45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent)",
    ...theme.applyStyles("dark", {
      backgroundColor: "#ff9500",
      backgroundSize: "1rem 1rem",
      backgroundImage:
        "linear-gradient(45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent)",
    }),
  },
}));

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [bindModal, setBindModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleClose();
    axios
      .post("/api/client/change", {
        oldPassword: data.get("oldPassword"),
        newPassword: data.get("newPassword"),
        confirmPassword: data.get("confirmPassword"),
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Changed successfully",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleClose();
    axios
      .post("/api/client/bind", {
        phone: data.get("phone"),
        wpassword: data.get("wpassword"),
        address: data.get("address"),
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Changed successfully",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleWSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleClose();
    axios
      .post("/api/client/changeW", {
        oldWPassword: data.get("oldWPassword"),
        newWPassword: data.get("newWPassword"),
        confirmWPassword: data.get("confirmWPassword"),
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Changed successfully",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleClose = () => {
    setOpen(false);
    setBindModal(false);
    setChangeModalOpen(false);
  };

  const handleLogout = () => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
    navigate("/login");
  };

  return (
    <Box
      sx={{ paddingTop: 10, paddingBottom: 8, width: "75%", margin: "auto" }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center p-2">
          <img
            src={`/images/vip${user.level}.png`}
            width={65}
            className="h-full"
          ></img>
          <div className="p-2">
            <div className="text-lg font-bold">
              {" "}
              {user.username + "-VIP " + user.level}
            </div>
            <div className="text-sm text-gray-500">
              {`Invitation Code: ${user.invitecode}`}
            </div>
            <div className="text-xs" style={{ color: "#FF9500" }}>
              {user.role === 0
                ? "Personal Account"
                : user.role === 1
                ? "Education Account"
                : "Admin Account"}
            </div>
          </div>
        </div>
        <div
          className="mr-6 text-orange-400 cursor-pointer hover:text-orange-600"
          onClick={() => {
            navigator.clipboard.writeText(user.invitecode);
            dispatch({
              type: MESSAGE_OPEN,
              payload: {
                type: "success",
                text: "Copied invite code",
              },
            });
          }}
        >
          <CopyAllOutlined />
        </div>
      </div>

      <div className="p-4">
        <div className="text-xs text-gray-500">Credit Score</div>
        <div className="relative mb-6">
          <BorderLinearProgress variant="determinate" value={100}>
            100%
          </BorderLinearProgress>
          <div
            style={{
              transform: "translateX(-50%)",
            }}
            className="absolute top-0 text-xs font-bold text-white left-1/2"
          >
            {"100%"}
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="w-full p-3 leading-8 bg-white shadow-md cursor-pointer">
              <div className="font-bold text-gray-900 text-md ">
                Account Balance
              </div>
              <div className="text-2xl font-bold" style={{ color: "#ff9500" }}>
                {user.wallet.toFixed(2)} <span className="text-sm">USDT</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="w-full p-3 leading-8 bg-white shadow-md cursor-pointer">
              <div className="font-bold text-gray-900 text-md ">
                Total Profits
              </div>
              <div className="text-2xl font-bold" style={{ color: "#ff9500" }}>
                {user.profit.toFixed(2)} <span className="text-sm">USDT</span>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="p-2 pt-4 mt-4 bg-white shadow-lg rounded-xl">
          <div className="ml-6 font-bold">Transactions</div>
          <MenuList>
            <Link to="/withdraw">
              <MenuItem>
                <ListItemIcon>
                  <VerticalAlignBottomOutlined />
                </ListItemIcon>
                <ListItemText>Withdraw</ListItemText>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <NavigateNextOutlined />
                </Typography>
              </MenuItem>
            </Link>
            <Divider sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
            <Link to="/deposit">
              <MenuItem>
                <ListItemIcon>
                  <VerticalAlignTopOutlined />
                </ListItemIcon>
                <ListItemText>Deposit</ListItemText>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <NavigateNextOutlined />
                </Typography>
              </MenuItem>
            </Link>
            <Divider sx={{ marginTop: "0.5rem" }} />
          </MenuList>

          <div className="ml-6 font-bold">Profile</div>
          <MenuList>
            <MenuItem
              onClick={() => {
                setChangeModalOpen(true);
              }}
            >
              <ListItemIcon>
                <LockOutlined />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                <NavigateNextOutlined />
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setBindModal(true);
              }}
            >
              <ListItemIcon>
                <ShieldOutlined />
              </ListItemIcon>
              <ListItemText>Bind Wallet Address</ListItemText>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                <NavigateNextOutlined />
              </Typography>
            </MenuItem>
            <Divider />
          </MenuList>

          <div className="ml-6 font-bold">Account</div>
          <MenuList>
            <MenuItem
              onClick={() => {
                setOpen(true);
              }}
            >
              <ListItemIcon>
                <HeadsetMicOutlined />
              </ListItemIcon>
              <ListItemText>Contact us</ListItemText>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                <NavigateNextOutlined />
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                <NavigateNextOutlined />
              </Typography>
            </MenuItem>
            <Divider />
          </MenuList>
        </div>
      </div>

      <ServiceModal open={open} handleClose={handleClose} />
      <ChangePassword
        open={changeModalOpen}
        handleClose={handleClose}
        handlePSubmit={handlePSubmit}
        handleWSubmit={handleWSubmit}
      />
      <BindAddress
        open={bindModal}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Profile;

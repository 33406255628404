import "./App.css";
import React from "react";
import { Snackbar, Alert } from "@mui/material";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "./components/Header";
import Login from "./pages/client/Login";
import Register from "./pages/client/Register";
import Index from "./pages/client/Index";
import Profile from "./pages/client/Profile";
import Faq from "./pages/client/Faq";
import Activity from "./pages/client/Activity";
import Start from "./pages/client/Start";
import ErrorPage from "./pages/404";
import Deposit from "./pages/client/Deposit";
import Withdraw from "./pages/client/Withdraw";

import AdminIndex from "./pages/admin/Index";

import PrivateRoute from "./components/PrivateRoute";
import PrivateAdminRoute from "./components/PrivateAdminRoute";

import { useDispatch, useSelector } from "react-redux";
import { MESSAGE_CLOSE } from "./redux/types";
import Record from "./pages/client/Record";

function App() {
  const message = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: MESSAGE_CLOSE });
  };

  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/admin",
      element: (
        <PrivateAdminRoute>
          <AdminIndex />
        </PrivateAdminRoute>
      ),
    },
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Header />
        </PrivateRoute>
      ),
      children: [
        {
          path: "/",
          element: <Index />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/records",
          element: <Record />,
        },
        {
          path: "/faqs",
          element: <Faq />,
        },
        {
          path: "/activity",
          element: <Activity />,
        },
        {
          path: "/deposit",
          element: <Deposit />,
        },
        {
          path: "/withdraw",
          element: <Withdraw />,
        },
        {
          path: "/start",
          element: <Start />,
        },
        {
          path: "/*",
          element: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <div>
      <Snackbar
        open={message.open}
        autoHideDuration={message.duration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={message.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message.text}
        </Alert>
      </Snackbar>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import {
  Close,
  HeadsetMicOutlined,
  NavigateNextOutlined,
} from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ServiceModal = ({ handleClose, open }) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Customer Service</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <div className="mb-2 ml-2 mr-2">
        <MenuList>
          <Link to="https://t.me/JackpotCustomerService" target="_blank">
            <MenuItem>
              <ListItemIcon>
                <HeadsetMicOutlined />
              </ListItemIcon>
              <ListItemText>Contact us</ListItemText>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                <NavigateNextOutlined />
              </Typography>
            </MenuItem>
          </Link>
          <Divider />
        </MenuList>
      </div>
    </Dialog>
  );
};

export default ServiceModal;

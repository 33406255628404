import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";

export default function NewSetDialog({ handleClose, open, handleOk }) {
  const [amount, setAmount] = React.useState("35,40,44,45");

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Make new set</DialogTitle>
      <DialogContent>
        <DialogContentText>Input combo task</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="combos"
          name="combos"
          label="Combos"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            handleOk(amount);
            setAmount("35,40,44,45");
          }}
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import {
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
  WorkOutlineOutlined,
  HeadsetMicOutlined,
} from "@mui/icons-material";
import { Box, Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import StyledButton from "../../components/StyledButton";
import { Link } from "react-router-dom";
import ServiceModal from "../../components/ServiceModal";

const Index = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{ paddingTop: 10, paddingBottom: 10, width: "75%", margin: "auto" }}
    >
      <Carousel>
        <img src="images/pic1.png" width={"100%"}></img>
        <img src="images/pic2.png" width={"100%"}></img>
        <img src="images/pic3.png" width={"100%"}></img>
      </Carousel>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Link to="/withdraw">
            <StyledButton variant="outlined" fullWidth>
              <VerticalAlignTopOutlined className="mr-2" htmlColor="#ff9500" />{" "}
              Withdraw
            </StyledButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link to="/deposit">
            <StyledButton variant="outlined" fullWidth>
              <VerticalAlignBottomOutlined
                className="mr-2"
                htmlColor="#ff9500"
              />{" "}
              Deposit
            </StyledButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link to={"/activity"}>
            <StyledButton variant="outlined" fullWidth>
              <WorkOutlineOutlined className="mr-2" htmlColor="#ff9500" />{" "}
              Activity
            </StyledButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledButton
            variant="outlined"
            fullWidth
            onClick={() => {
              setOpen(true);
            }}
          >
            <HeadsetMicOutlined className="mr-2" htmlColor="#ff9500" /> Service
          </StyledButton>
        </Grid>
      </Grid>
      <Divider className="p-4" textAlign="left" />
      <div className="mt-4 mb-4 text-xl font-bold">VIP LEVEL</div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={6} xs={12}>
          <div className="w-full h-full bg-white shadow-md">
            <div
              style={{ backgroundColor: "#ff9500" }}
              className="w-16 text-lg font-bold text-center text-white max-h-10h-10"
            >
              VIP 1
            </div>
            <div className="w-full">
              <img src="images/vip1.png" className="mx-auto" width={110}></img>
            </div>
            <div className="p-2 text-gray-600">
              Suitable for most data capture scenarios involving light to medium
              usage
              <br /> Profit of 0.5% per task, you complete 45 tasks per set Up
              to 135 optimise ratings per day
              <br /> No access to other Premium features
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <div className="w-full h-full bg-white shadow-md">
            <div
              style={{ backgroundColor: "#ff9500" }}
              className="w-16 text-lg font-bold text-center text-white max-h-10h-10"
            >
              VIP 2
            </div>
            <div className="w-full">
              <img src="images/vip2.png" className="mx-auto" width={110}></img>
            </div>
            <div className="p-2 text-gray-600">
              Deposit in accordance with our renewal event <br /> Profit of 1%
              per task, you complete 50 tasks per set
              <br /> Better Profit and permission Up to 150 tasks per day can be
              submitted by Vip 2 users
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <div className="w-full h-full bg-white shadow-md">
            <div
              style={{ backgroundColor: "#ff9500" }}
              className="w-16 text-lg font-bold text-center text-white max-h-10h-10"
            >
              VIP 3
            </div>
            <div className="w-full">
              <img src="images/vip3.png" className="mx-auto" width={110}></img>
            </div>
            <div className="p-2 text-gray-600">
              Deposit in accordance with our renewal event <br /> Profit of 1.5%
              per task, you complete 55 tasks per set
              <br /> Better Profit and permission Up to 165 tasks per day can be
              submitted by Vip 3 users
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <div className="w-full h-full bg-white shadow-md">
            <div
              style={{ backgroundColor: "#ff9500" }}
              className="w-16 text-lg font-bold text-center text-white max-h-10h-10"
            >
              VIP 4
            </div>
            <div className="w-full">
              <img src="images/vip4.png" className="mx-auto" width={110}></img>
            </div>
            <div className="p-2 text-gray-600">
              Deposit in accordance with our renewal event <br /> Profit of 2%
              per task, you complete 60 tasks per set <br /> Better Profit and
              permission Up to 300 tasks per day can be submitted by Vip 4 users
            </div>
          </div>
        </Grid>
      </Grid>

      <ServiceModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Index;

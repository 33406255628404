import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import RecordCard from "../../components/RecordCard";

const Record = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/client/records")
      .then((res) => {
        if (res.data.success) {
          setData(res.data.logs);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.expired) navigate("/login");
      });
  }, []);
  return (
    <Box
      sx={{ paddingTop: 10, paddingBottom: 10, width: "75%", margin: "auto" }}
    >
      {data.map((product, index) => {
        return <RecordCard key={index} product={product} />;
      })}
    </Box>
  );
};

export default Record;

import { Star } from "@mui/icons-material";
import React from "react";

const ProductCard = ({ product }) => {
  return (
    <div className="flex items-center p-2 mb-2 border-2 shadow-lg">
      <div>
        <img src={"/images/products/" + product.image} width={120} alt="" />
      </div>
      <div className="ml-2">
        <div className="text-sm font-bold">
          4.5 <Star size="small" style={{ color: "#ff9500" }} />
        </div>
        <div className="font-bold text-md">{product.name}</div>
        <div className="text-lg font-bold" style={{ color: "#ff9500" }}>
          ${product.price}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

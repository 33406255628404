import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Fab from "@mui/material/Fab";

import { createTheme, styled, ThemeProvider } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AccountCircleOutlined,
  HomeOutlined,
  TextSnippetOutlined,
  HelpOutline,
  LogoutOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  HeadsetMicOutlined,
  TaskAltOutlined,
  AdminPanelSettingsOutlined,
} from "@mui/icons-material";

import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../redux/types";
import axios from "axios";
import ServiceModal from "./ServiceModal";

const drawerWidth = 250;
const navItems = [
  { text: "Home", icon: <HomeOutlined htmlColor="#ff9500" />, to: "/" },
  {
    text: "Records",
    icon: <TextSnippetOutlined htmlColor="#ff9500" />,
    to: "/records",
  },
  {
    text: "Deposit",
    icon: <VerticalAlignBottomOutlined htmlColor="#ff9500" />,
    to: "/deposit",
  },
  {
    text: "Withdraw",
    icon: <VerticalAlignTopOutlined htmlColor="#ff9500" />,
    to: "/withdraw",
  },
  { text: "FAQs", icon: <HelpOutline htmlColor="#ff9500" />, to: "/faqs" },
  {
    text: "Profile",
    icon: <AccountCircleOutlined htmlColor="#ff9500" />,
    to: "/profile",
  },
  {
    text: "Logout",
    icon: <LogoutOutlined htmlColor="#ff9500" />,
    to: "/logout",
  },
];

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "60px",
  height: "60px",
  backgroundImage: "url(./images/light.svg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "white",
  borderRadius: 0,
  backgroundColor: "rgba(0,0,0,0)",
  border: "none",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    boxShadow: "none",
  },
  "&:focus": {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    boxShadow: "none",
  },
});

const defaultTheme = createTheme({
  typography: {},
});

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1976d2",
    },
  },
  typography: {},
});

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
    navigate("/login");
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", padding: "5px", textAlign: "left" }}
    >
      <div className="flex items-center p-2">
        <img
          src={`/images/vip${user.level}.png`}
          width={65}
          className="h-full"
        ></img>
        <div className="p-2">
          <div className="text-lg font-bold">
            {" "}
            {user.username + "-VIP " + user.level}
          </div>
          <div
            style={{ color: "#ff9500" }}
          >{`Account Balance: ${user.wallet.toFixed(2)} USDT`}</div>
        </div>
      </div>
      <List>
        {navItems.map((item) => {
          if (item.text === "Logout")
            return (
              <ListItemButton key={item.text} onClick={handleLogout}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            );
          else
            return (
              <Link key={item.text} to={item.to}>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              </Link>
            );
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar component="nav">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "white",
          }}
        >
          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon htmlColor="rgb(10, 10, 10)" fontSize="30" />
          </IconButton>
          <Link to={"/"}>
            <img
              src={"/favicon.png"}
              alt={"jackpot"}
              loading="lazy"
              width={80}
            />
          </Link>
          <Link to="/profile">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircleOutlined
                htmlColor="rgb(10, 10, 10)"
                fontSize="30"
              />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      <div id="detail">
        <Outlet />
      </div>
      <AppBar
        position="fixed"
        component="nav"
        color="primary"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "white",
          }}
        >
          <StyledFab
            color="secondary"
            onClick={() => {
              navigate("/start");
            }}
          >
            <TaskAltOutlined fontSize="large" />
          </StyledFab>

          <Link to="/">
            <div className="text-center text-gray-800 cursor-pointer md:ml-16 hover:text-orange-400">
              <div className="ml-2 mr-2">
                <HomeOutlined />
              </div>
              <div className="text-xs">Home</div>
            </div>
          </Link>
          <div
            className="text-center text-gray-800 cursor-pointer hover:text-orange-400"
            onClick={() => {
              setOpen(true);
            }}
          >
            <div className="ml-2 mr-2">
              <HeadsetMicOutlined />
            </div>
            <div className="text-xs">Service</div>
          </div>
          <Link to="/records">
            <div className="text-center text-gray-800 cursor-pointer hover:text-orange-400">
              <div className="ml-2 mr-2">
                <TextSnippetOutlined />
              </div>
              <div className="text-xs">Records</div>
            </div>
          </Link>
          <Link to="/profile">
            <div className="text-center text-gray-800 cursor-pointer md:mr-16 hover:text-orange-400">
              <div className="ml-2 mr-2">
                <AccountCircleOutlined />
              </div>
              <div className="text-xs">Profile</div>
            </div>
          </Link>
        </Toolbar>
      </AppBar>

      <ServiceModal open={open} handleClose={handleClose} />

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundImage: "url(/images/sidebar.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            },
          }}
          width={10}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              backdropFilter: "blur(2px)",
              zIndex: -1,
            }}
          ></div>
          {drawer}
        </Drawer>
      </nav>
    </ThemeProvider>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;

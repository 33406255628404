import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";

export default function DepositDialog({ handleClose, open, handleOk }) {
  const [amount, setAmount] = React.useState(0);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Depoist</DialogTitle>
      <DialogContent>
        <DialogContentText>Input deposit amount</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="deposit"
          name="deposit"
          label="Deposit amount"
          type="number"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            const newAmount = Number(amount);
            // if (newAmount > 0) {
            handleOk(newAmount);
            setAmount(0);
            // }
          }}
          variant="contained"
        >
          Deposit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import {
  AppBar,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import GeneralButton from "./GeneralButton";
import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ChangePassword = ({
  handleClose,
  open,
  handlePSubmit,
  handleWSubmit,
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
      }}
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Change Password</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <div className="p-2 mb-2 ml-2 mr-2">
        <AppBar
          position="static"
          sx={{ backgroundColor: "white", color: "gray" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "orange",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#ff9500",
              },
            }}
            textColor="inherit"
          >
            <Tab label="Change Login" {...a11yProps(0)} />
            <Tab label="Change Withdraw" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box
            component="form"
            onSubmit={handlePSubmit}
            noValidate={false}
            sx={{ mt: 1 }}
          >
            <TextField
              id="oldPassword"
              name="oldPassword"
              fullWidth
              required
              type="password"
              sx={{ marginTop: 3 }}
              label="Old Login Password"
              autoFocus
            />
            <TextField
              fullWidth
              type="password"
              required
              id="newPassword"
              name="newPassword"
              sx={{ marginTop: 3 }}
              label="New Login Password"
            />
            <TextField
              name="confirmPassword"
              id="confirmPassword"
              fullWidth
              required
              type="password"
              sx={{ marginTop: 3, marginBottom: 3 }}
              label="Confirm New Login Password"
            />
            <GeneralButton type="submit">Update</GeneralButton>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box
            component="form"
            onSubmit={handleWSubmit}
            noValidate={false}
            sx={{ mt: 1 }}
          >
            <TextField
              fullWidth
              required
              type="password"
              id="oldWPassword"
              name="oldWPassword"
              sx={{ marginTop: 3 }}
              label="Old Withdraw Password"
              autoFocus
            />
            <TextField
              fullWidth
              required
              type="password"
              id="newWPassword"
              name="newWPassword"
              sx={{ marginTop: 3 }}
              label="New Withdraw Password"
            />
            <TextField
              fullWidth
              required
              type="password"
              name="confirmWPassword"
              id="confirmWPassword"
              sx={{ marginTop: 3, marginBottom: 3 }}
              label="Confirm New Withdraw Password"
            />
            <GeneralButton type="submit">Update</GeneralButton>
          </Box>
        </TabPanel>
      </div>
    </Dialog>
  );
};

export default ChangePassword;

import React from "react";
import Moment from "react-moment";
import { Chip } from "@mui/material";

const RecordCard = ({ product }) => {
  return (
    <div className="flex items-center justify-between p-3 mb-2 bg-white border-2 shadow-lg">
      <div className="flex items-center">
        <div>
          <img src={"/images/products/" + product.image} width={90} alt="" />
        </div>
        <div className="ml-2">
          <div className="ml-1 text-sm text-gray-600">
            <Moment format="YYYY-MM-DD hh-mm-ss">{product.created}</Moment>
          </div>
          <div className="">{product.name}</div>
          <div className="ml-1 font-bold" style={{ color: "#ff9500" }}>
            ${Number(product.price.toFixed(2))}
          </div>
          <div className="ml-1 text-sm text-gray-600">
            Profits:{" "}
            <span className="font-bold">
              ${Number(product.profit.toFixed(2))}
            </span>
          </div>
        </div>
      </div>
      <Chip
        color={product.state === "PENDING" ? "warning" : "success"}
        label={product.state}
        size="small"
      />
    </div>
  );
};

export default RecordCard;

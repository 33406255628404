import { HeadsetMicOutlined } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GeneralButton from "../../components/GeneralButton";
import ServiceModal from "../../components/ServiceModal";
import { useNavigate } from "react-router-dom";
import DepositCard from "../../components/DepositCard";

import axios from "axios";

const Deposit = () => {
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/client/deposit")
      .then((res) => {
        if (res.data.success) {
          setData(res.data.logs);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.expired) navigate("/login");
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{ paddingTop: 10, paddingBottom: 10, width: "75%", margin: "auto" }}
    >
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center p-2">
          <img
            src={`/images/vip${user.level}.png`}
            width={65}
            className="h-full"
          ></img>
          <div className="p-2">
            <div className="text-lg font-bold">
              {" "}
              {user.username + "-VIP " + user.level}
            </div>
            <div className="text-sm text-gray-500">
              Contact support to make a deposit
            </div>
          </div>
        </div>
        <div
          className="mr-6 text-orange-400 cursor-pointer hover:text-orange-600"
          onClick={() => {
            setOpen(true);
          }}
        >
          <HeadsetMicOutlined />
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className="w-full p-3 leading-8 bg-white shadow-md cursor-pointer">
            <div className="font-bold text-gray-900 text-md ">
              Account Balance
            </div>
            <div className="text-2xl font-bold" style={{ color: "#ff9500" }}>
              {user.wallet.toFixed(2)} <span className="text-sm">USDT</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="w-full p-3 leading-8 bg-white shadow-md cursor-pointer">
            <div className="font-bold text-gray-900 text-md ">
              Total Profits
            </div>
            <div className="text-2xl font-bold" style={{ color: "#ff9500" }}>
              {user.profit.toFixed(2)} <span className="text-sm">USDT</span>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="mt-4">
        <GeneralButton
          onClick={() => {
            setOpen(true);
          }}
        >
          Contact Customer Service
        </GeneralButton>
      </div>

      {data.map((log, index) => {
        return <DepositCard key={index} log={log} />;
      })}

      <ServiceModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Deposit;

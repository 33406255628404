import { Dialog, DialogTitle, IconButton, Divider, Alert } from "@mui/material";
import React from "react";
import GeneralButton from "./GeneralButton";
import { Close } from "@mui/icons-material";
import ProductCard from "./ProductCard";
import Moment from "react-moment";

const OptimizeModal = ({
  handleClose,
  open,
  data,
  handleOptimize,
  optimizing,
}) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>Optimize Products</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      {data.products.length > 0 && (
        <div className="pb-4 pl-4 pr-4">
          {data.products.map((product, index) => {
            return <ProductCard product={product} key={index} />;
          })}
          {data.products.length > 1 && (
            <Alert variant="filled" severity="success">
              Congratulations! You met combos. You can earn 6 times of your
              profit.
            </Alert>
          )}
          <div className="p-4 mt-2 mb-2 border-2 shadow-md">
            <div className="flex items-center justify-between mt-1">
              <div>Created:</div>
              <Moment format="YYYY-MM-DD hh:mm:ss">
                {data.products[0].created}
              </Moment>
            </div>
            <div className="flex items-center justify-between mt-1">
              <div>Profit:</div>
              <div className="font-bold">${data.profit.toFixed(2)}</div>
            </div>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <div className="flex items-center justify-between">
              <div>Total Price:</div>
              <div className="text-xl font-bold">${data.total.toFixed(2)}</div>
            </div>
          </div>
          <GeneralButton onClick={handleOptimize} disabled={optimizing}>
            {optimizing ? "Optimizing ..." : "Optimize"}
          </GeneralButton>
        </div>
      )}
    </Dialog>
  );
};

export default OptimizeModal;

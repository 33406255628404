import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import GeneralButton from "./GeneralButton";
import { Close } from "@mui/icons-material";

const BindAddress = ({ handleClose, open, handleSubmit }) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
      }}
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Bind Wallet Address</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <div className="pb-4 pl-4 pr-4">
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate={false}
          sx={{ mt: 1 }}
        >
          <OutlinedInput
            id="phone"
            name="phone"
            required
            fullWidth
            placeholder="Phone number with country code"
            autoFocus
          />
          <OutlinedInput
            fullWidth
            id="address"
            required
            name="address"
            sx={{ marginTop: 1 }}
            placeholder="Wallet Address(USDT)"
            endAdornment={<InputAdornment position="end">TRC20</InputAdornment>}
          />
          <div className="mt-1 ml-2 text-xs font-bold text-gray-500">
            Paste your usdt wallet address <br />
            Strickly use TRC20 network address
          </div>
          <OutlinedInput
            id="wpassword"
            name="wpassword"
            fullWidth
            required
            type="password"
            sx={{ marginTop: 1, marginBottom: 3 }}
            placeholder="Withdraw Password"
          />
          <GeneralButton type="submit">Submit</GeneralButton>
        </Box>
      </div>
    </Dialog>
  );
};

export default BindAddress;

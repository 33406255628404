import { NavigateNextOutlined } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import GeneralButton from "../../components/GeneralButton";
import axios from "axios";
import OptimizeModal from "../../components/OptimizeModal";
import { SET_WALLET, MESSAGE_OPEN } from "../../redux/types";

const Start = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [data, setData] = useState({
    products: [],
    profit: 0,
    total: 0,
  });
  const [open, setOpen] = useState(false);
  const [optimizing, setOptimizing] = useState(false);

  const dispatch = useDispatch();

  const handleOptimize = () => {
    setOptimizing(true);
    setTimeout(() => {
      axios
        .post("/api/client/optimize")
        .then((res) => {
          if (res.data.success) {
            handleClose();
            setOptimizing(false);
            dispatch({
              type: SET_WALLET,
              payload: {
                wallet: res.data.wallet,
                profit: res.data.profit,
                task: res.data.task,
                set: res.data.set,
                vip: res.data.vip,
              },
            });
            dispatch({
              type: MESSAGE_OPEN,
              payload: {
                type: "success",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "error",
              text: err?.response?.data?.message
                ? err.response.data.message
                : "Server error",
            },
          });
          if (err?.response?.data?.expired) navigate("/login");
          setOptimizing(false);
        });
    }, 1000);
  };

  const handleStart = () => {
    axios
      .get("/api/client/optimize")
      .then((res) => {
        if (res.data.success) {
          if (res.data.products.length > 0) {
            setData({
              products: res.data.products,
              profit: res.data.profit,
              total: res.data.total,
            });
            setOpen(true);
          }
        } else {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "warning",
              text: res.data.message,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ paddingTop: 9, paddingBottom: 9, width: "75%", margin: "auto" }}>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center p-2">
          <img
            src={`/images/vip${user.level}.png`}
            width={65}
            className="h-full"
          ></img>
          <div className="p-2">
            <div className="text-lg font-bold">
              {" "}
              {user.username + "-VIP " + user.level}
            </div>
            <div className="text-sm text-gray-600">
              Consecutive working days: 1
            </div>
          </div>
        </div>
        <div
          className="mr-6 text-gray-900 cursor-pointer hover:text-gray-700"
          onClick={() => {
            navigate("/");
          }}
        >
          Exit tasks area <NavigateNextOutlined />
        </div>
      </div>

      <div className="pl-2 pr-2">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="flex items-center w-full h-full p-3 leading-8 bg-white shadow-md cursor-pointer">
              <div>
                <img src="/images/earning.png" width={100} />
              </div>
              <div className="w-full ml-3">
                <div className="text-lg font-bold text-gray-900 ">
                  Total Earnings
                </div>
                <div className="text-sm text-gray-500 ">
                  All earnings are automatically updated
                </div>
                <div
                  className="flex items-start justify-between mt-2 font-bold"
                  style={{ color: "#ff9500" }}
                >
                  <div className="text-xl">
                    {user.profit.toFixed(2)}
                    <span className="text-sm"> USDT</span>
                  </div>
                  <Link to="/records">
                    <span className="mr-3 text-sm">View records</span>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="flex items-center w-full h-full p-3 leading-8 bg-white shadow-md cursor-pointer">
              <div>
                <img src="/images/wallet.png" width={100} />
              </div>
              <div className="w-full ml-3">
                <div className="text-lg font-bold text-gray-900 ">
                  Total Balance
                </div>
                <div className="text-sm text-gray-500 ">
                  Profits earned from each task will be added to the total asset
                  balance
                </div>
                <div
                  className="flex items-start justify-between mt-2 font-bold"
                  style={{ color: "#ff9500" }}
                >
                  <div className="text-xl">
                    {user.wallet.toFixed(2)}{" "}
                    <span className="text-sm">USDT</span>
                  </div>
                  <Link to="/deposit">
                    <span className="mr-3 text-sm">Add cash</span>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="mt-4">
          <GeneralButton onClick={handleStart} disabled={user.vip === 0}>
            {user.vip === 0
              ? "Contact customer service and create new set"
              : "Start Task"}
          </GeneralButton>
        </div>
        <div className="mt-4">
          <div className="absolute p-3 text-3xl font-bold text-gray-700">
            {user.vip !== 0 ? user.task + "/" + (user.vip * 5 + 40) : "0/0"}
            <span className="text-lg"> tasks</span>
          </div>
          <img src="/images/market.gif" alt="market" width={"100%"} />
        </div>
      </div>

      <OptimizeModal
        open={open}
        handleClose={handleClose}
        data={data}
        handleOptimize={handleOptimize}
        optimizing={optimizing}
      />
    </Box>
  );
};

export default Start;

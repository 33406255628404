import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Divider, OutlinedInput } from "@mui/material";
import GeneralButton from "../../components/GeneralButton";

import axios from "axios";
import DepositCard from "../../components/DepositCard";
import { useNavigate } from "react-router-dom";
import { MESSAGE_OPEN } from "../../redux/types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Withdraw = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [money, setMoney] = React.useState("");
  const [data, setData] = React.useState([]);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getWithdraw = () => {
    axios
      .get("/api/client/withdraw")
      .then((res) => {
        if (res.data.success) {
          setData(res.data.logs);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  React.useEffect(() => {
    getWithdraw();
  }, []);

  const handleSubmit = () => {
    axios
      .post("/api/client/withdraw", {
        money,
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Request successfully",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ paddingTop: 8, paddingBottom: 16, width: "75%", margin: "auto" }}
    >
      <AppBar
        position="static"
        sx={{ backgroundColor: "white", color: "gray" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "orange",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#ff9500",
            },
          }}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Withdraw" {...a11yProps(0)} />
          <Tab label="History" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <div className="p-3 bg-white shadow-md rounded-xl">
          <div className="mb-2 text-lg font-bold">
            Balance: {user.wallet.toFixed(2)}{" "}
            <span className="text-sm">USDT</span>
          </div>
          <Divider />
          <div className="mt-4">
            <OutlinedInput
              fullWidth
              autoFocus
              value={money}
              onChange={(e) => setMoney(e.target.value)}
              id="input-with-icon-textfield"
              type="number"
              placeholder="Amount to withdraw"
            />
          </div>
          <div className="mt-4">
            <GeneralButton onClick={handleSubmit}>
              Submit Withdraw
            </GeneralButton>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {data.map((log, index) => {
          return <DepositCard key={index} log={log} />;
        })}
      </TabPanel>
    </Box>
  );
};

export default Withdraw;

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const GeneralButton = styled(Button)({
  boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
  textTransform: "none",
  fontSize: 20,
  padding: "12px 12px",
  border: "none",
  borderRadius: "0px",
  lineHeight: 1.5,
  color: "white",
  border: "none",
  width: "100%",
  backgroundColor: "#ff9500",
  "&:hover": {
    backgroundColor: "#f09530",
    border: "none",
  },
  "&:active": {
    backgroundColor: "#f89030",
    border: "none",
  },
  "&:focus": {
    backgroundColor: "#f08050",
    border: "none",
  },
});

export default GeneralButton;

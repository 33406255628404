import { LOADING, LOGIN_SUCCESS, LOGOUT, SET_WALLET } from "../types";

const initialState = {
  user: {},
  loading: true,
  login: false,
};

const caculateLevel = (wallet) => {
  if (wallet < 800) return 1;
  else if (wallet < 2000) return 2;
  else if (wallet < 5000) return 3;
  else return 4;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        loading: false,
        user: {
          ...action.payload,
          level: caculateLevel(action.payload.wallet),
        },
      };
    case LOGOUT:
      return {
        ...state,
        loading: false,
        login: false,
        user: {},
      };
    case SET_WALLET:
      return {
        ...state,
        user: {
          ...state.user,
          wallet: action.payload.wallet,
          profit: action.payload.profit,
          task: action.payload.task,
          set: action.payload.set,
          vip: action.payload.vip,
          level: caculateLevel(action.payload.wallet),
        },
      };
    case LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }
}

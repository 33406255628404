import { Box } from "@mui/material";
import React from "react";

const Activity = () => {
  return (
    <Box sx={{ paddingTop: 8, paddingBottom: 8, width: "75%", margin: "auto" }}>
      <img src="images/act3.png"></img>
      <img src="images/act2.png"></img>
      <img src="images/act1.png"></img>
    </Box>
  );
};

export default Activity;

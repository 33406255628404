import { Box } from "@mui/material";
import React from "react";

const FAQ = () => {
  return (
    <Box
      sx={{ paddingTop: 12, paddingBottom: 10, width: "75%", margin: "auto" }}
    >
      <div className="text-lg font-bold">Frequently Asked Questions</div>

      <div className="p-4 mt-6 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          1. Start Optimization Task
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          1(1). A minimum 100 USDT recharge is required to reset the account to
          start new task, excluding the account balance （Except for the first
          reset user）
          <br /> 1(2). Once all tasks are completed, the user must request a
          full withdrawal and receive the withdrawal amount before requesting to
          reset the account.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          2. Withdrawals
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          2(1). If the withdrawal amount is 10,000 USDT or more, please contact
          our online customer service to make a withdrawal. <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I. The maximum withdrawal amount
          for VIP1 users is 5,000 USDT <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;II. The maximum withdrawal amount
          for VIP2 users is 10,000 USDT
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; III. The maximum withdrawal
          amount for VIP3 users is 20,000 USDT
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IV. The maximum withdrawal amount
          for VIP4 users is 100,000 USDT. <br />
          2(2). After completing all tasks, you can apply for full withdrawal.{" "}
          <br />
          2(3). Users need to complete all tasks in order to request a
          withdrawal. <br />
          2(4). If you choose to give up or withdraw during the task
          optimization process, you cannot request a withdrawal or refund.{" "}
          <br />
          2(5). Withdrawals cannot be processed if no withdrawal request is
          received from the user.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          3. Funds
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          3(1). All funds will be held securely in the user's account and can be
          requested in full once all product optimizations are completed. <br />
          3(2). In order to avoid any loss of funds, all data will be processed
          by the system and not manually. <br />
          3(3). The platform will take full responsibility for any accidental
          loss of funds.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          4. Account Security
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          4(1). Please do not disclose your login password and security code to
          others, and the platform will not be responsible for any loss. <br />
          4(2). Users are not recommended to use their birthday date, ID card
          number, cell phone number, etc. as the security code or login
          password.
          <br /> 4(3). If you forget your login password or security code,
          please contact our online customer service to reset it.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          5. Normal Products
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          5(1). Platform earnings are divided into normal earnings and six times
          earnings. Each Premium/Regular user will have from 1 to 8
          opportunities for six times earnings during the day's optimization
          tasks. All users will normally only receive a maximum of 1-3
          combination product assignments. <br />
          5(2). VIP 1 will receive 0.5% of the profit for each normal
          optimization product.
          <br />
          5(3). VIP 1 will get 3% profit from each combined product.
          <br />
          5(4). Funds and earnings will be credited back to the user's account
          for each optimization task completed.
          <br />
          5(5). The system will randomly assign tasks to the user's account
          based on the total amount in the user's account. <br />
          5(6). Once a task has been assigned to a user's account, it cannot be
          cancelled or skipped.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          6. Combination tasks
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          6(1). Combination product consists of 1-3 products, the user may not
          necessarily get 3 products, the system will randomly assign the normal
          goods and combination products, in the combination goods, the user has
          more chances to get 1-2 products. <br />
          6(2). User will receive 6 times more commissions for each product in
          the portfolio product than for the regular product. <br />
          6(3). When you receive a Combination Product, all funds will be used
          for product trade submissions and will be returned to your account
          balance after you complete the data for each product in the
          Combination Product. <br />
          6(4). The system will randomly allocate combination products based on
          the total balance of the User's account. 6(5). Once a combination
          product is assigned to a user's account, it cannot be canceled or
          skipped.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          7. Deposit
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          7(1). The amount of the deposit is chosen by the User. We cannot
          decide the amount of the deposit for the User and we suggest that the
          User can make an advance payment according to his ability.
          <br /> 7(2). If the User needs to pay a deposit when receiving the
          combined product, we suggest that the User is able to make an advance
          payment based on the amount of the shortfall shown on the account.{" "}
          <br />
          7(3). Users need to confirm the address with the customer service
          before depositing, if the user transfers to the wrong address, the
          platform is not responsible for it. <br />
          7(4). The Platform will not be held responsible if the User deposits
          into the wrong deposit account.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          8. Cooperation of Merchants
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          8(1). The platform has different tasks online and offline every time,
          if the tasks are not optimized for a long time, the merchant will not
          be able to unload the tasks, which will affect the progress of the
          merchant, it is recommended that users complete all tasks and product
          withdrawals as soon as possible to avoid affecting the progress of the
          merchant. <br />
          8(2). The merchant will provide a deposit detail for the user to
          deposit. <br />
          8(3). Any delay in completing tasks will be detrimental to the
          merchant and the process.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          9. Invitation
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          9(1). Only VIP3 users can invite new users, they need to work 10
          working days before they are eligible to invite new users. <br />
          9(2). It will not be possible to invite other users if the account has
          not completed all product optimizations.
        </div>
      </div>

      <div className="p-4 mt-4 bg-white shadow-md">
        <div className="text-lg font-bold cursor-pointer hover:text-yellow-700">
          10. Operating Hours
        </div>
        <div className="mt-2 text-sm leading-8 text-gray-600">
          10(1). Platform operating hours 10:00 to 22:59:59 (UTC-05:00). <br />
          10(2). Online customer service hours 10:00 to 22:59:59 (UTC-05:00).{" "}
          <br />
          10(3). Withdrawal operation hours 10:00 to 22:59:59 (UTC-05:00).
        </div>
      </div>
    </Box>
  );
};

export default FAQ;

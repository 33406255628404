import { Chip } from "@mui/material";
import React from "react";
import Moment from "react-moment";

const DepositCard = ({ log }) => {
  return (
    <div className="flex items-center justify-between p-3 mt-3 bg-white border-2 shadow-lg">
      <div className="ml-2">
        <div className="ml-1 text-sm text-gray-600">
          <Moment format="YYYY-MM-DD hh-mm-ss">{log.created}</Moment>
        </div>
        <div className="ml-1 text-lg" style={{ color: "#ff9500" }}>
          Amount:{" "}
          <span className="font-bold">${Number(log.amount.toFixed(2))}</span>
        </div>
      </div>
      <Chip
        color={
          log.state === "PENDING"
            ? "warning"
            : log.state === "COMPLETED"
            ? "success"
            : "error"
        }
        label={log.state ? log.state : "COMPLETED"}
        size="small"
      />
    </div>
  );
};

export default DepositCard;

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { LOGIN_SUCCESS, MESSAGE_OPEN } from "../../redux/types";

import { useNavigate, Link } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="https://jackpotrecords.com/" _target="blank">
        Jackpot Records
      </Link>{" "}
      {new Date().getFullYear()} {"."}
    </Typography>
  );
}

const defaultTheme = createTheme({
  palette: {
    backgroundColor: "rgb(100, 100, 100)",
  },
});

export default function Register() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.login) {
      navigate("/");
    }
  }, [auth.login]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios
      .post("/api/auth/register", {
        username: data.get("username"),
        password: data.get("password"),
        phone: data.get("phonenumber"),
        invitecode: data.get("invitecode"),
        wpassword: data.get("wpassword"),
      })
      .then((res) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "success",
            text: "Login successfully",
          },
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.payload,
        });
        localStorage.setItem("token", res.data.token);
        axios.defaults.headers.common.Authorization = res.data.token;
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
      });
  };

  return (
    <div className="p-5 mt-3">
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Link to={"https://jackpotrecords.com/"} target="_blanck">
              <img src="/images/logo.png" width={240}></img>
            </Link>
            {/* <Typography component="h1" variant="h5">
              Register New Account
            </Typography> */}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate={false}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoFocus
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="phonenumber"
                label="Phone Number"
                name="phonenumber"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="invitecode"
                label="Invite Code"
                name="invitecode"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="wpassword"
                label="Withdraw Password"
                type="password"
                id="wpassword"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                register
              </Button>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                OR
              </Box>
              <Link to={"/login"}>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  login
                </Button>
              </Link>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}

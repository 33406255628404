import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, Chip } from "@mui/material";

import AlertDialog from "../../components/AlertDialog";
import DepositDialog from "../../components/DepositDialog";
import NewSetDialog from "../../components/NewSetDialog";
import { useDispatch } from "react-redux";

import { MESSAGE_OPEN } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import CreateAccount from "../../components/CreateAccount";

export default function Index() {
  const [rows, setRows] = React.useState([]);
  const [wrows, setWRows] = React.useState([]);
  const [invitecode, setInviteCode] = React.useState("");
  const [selected, setSelectedUser] = React.useState(null);
  const [newSetConfirm, setNewSetConfirm] = React.useState(false);
  const [resetConfirm, setResetConfirm] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const [depositConfirm, setDepositConfirm] = React.useState(false);
  const [create, setCreate] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUsers = () => {
    axios
      .get("/api/admin/users")
      .then((res) => {
        if (res.data.success) {
          const newRows = res.data.users.map((user) => {
            return {
              ...user,
              vip: "VIP-" + user.vip,
              profit: user.profit.toFixed(2) + " USDT",
              wallet: user.wallet.toFixed(2) + " USDT",
            };
          });

          setInviteCode(res.data.invitecode);
          setRows(newRows);
          setWRows(res.data.withdraw);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  const handleClose = () => {
    setNewSetConfirm(false);
    setResetConfirm(false);
    setDeleteConfirm(false);
    setDepositConfirm(false);
    setCreate(false);
  };

  const handleCreate = (event) => {
    event.preventDefault();
    handleClose();
    const form = new FormData(event.currentTarget);

    axios
      .post("/api/admin/create", {
        username: form.get("username"),
        invitecode: form.get("invitecode"),
        invitename: form.get("invitename"),
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Create set successfully",
            },
          });
          getUsers();
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleNewSet = (combos) => {
    handleClose();
    axios
      .post("/api/admin/newsets", { id: selected, combos })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Create set successfully",
            },
          });
          getUsers();
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleDelete = () => {
    handleClose();
    axios
      .post("/api/admin/delete", { id: selected })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Deleted user successfully",
            },
          });
          getUsers();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleReset = () => {
    handleClose();
    axios
      .post("/api/admin/reset", { id: selected })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Reset successfully",
            },
          });
          getUsers();
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const handleDepoist = (amount) => {
    handleClose();
    axios
      .post("/api/admin/deposit", { id: selected, amount })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: MESSAGE_OPEN,
            payload: {
              type: "success",
              text: "Deposit successfully",
            },
          });
          getUsers();
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_OPEN,
          payload: {
            type: "error",
            text: err?.response?.data?.message
              ? err.response.data.message
              : "Server error",
          },
        });
        if (err?.response?.data?.expired) navigate("/login");
      });
  };

  const columns = [
    { field: "username", headerName: "User Name", width: 150 },
    { field: "phone", headerName: "Phone", width: 100 },
    { field: "wallet", headerName: "Wallet", width: 130 },
    { field: "profit", headerName: "Profit", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    {
      field: "role",
      headerName: "Role",
      width: 100,
      renderCell: ({ row }) => {
        if (row.role === 2) return <Chip label={"ADMIN"} color="success" />;
        else if (row.role === 0) return <Chip label="USER" color="info" />;
        else if (row.role === 1)
          return <Chip label="EDUCATION" color="warning" />;
      },
    },
    { field: "set", headerName: "Set", width: 100 },
    { field: "vip", headerName: "Start Level", width: 100 },
    { field: "task", headerName: "Task", width: 100 },
    { field: "combos", headerName: "Combos", width: 100 },
    {
      field: "date",
      headerName: "Action",
      hideable: false,
      width: 500,
      renderCell: ({ row }) => {
        return (
          <div className="p-1 text-center">
            <Button
              variant="contained"
              sx={{ margin: 1 }}
              disabled={row.role === 2}
              onClick={() => {
                setResetConfirm(true);
                setSelectedUser(row.id);
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              sx={{ margin: 1 }}
              disabled={row.role === 2}
              onClick={() => {
                setNewSetConfirm(true);
                setSelectedUser(row.id);
              }}
            >
              New set
            </Button>
            <Button
              variant="contained"
              sx={{ margin: 1 }}
              disabled={row.role === 2}
              onClick={() => {
                setDepositConfirm(true);
                setSelectedUser(row.id);
              }}
            >
              Deposit
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ margin: 1 }}
              disabled={row.role === 2}
              onClick={() => {
                setDeleteConfirm(true);
                setSelectedUser(row.id);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const wcolumns = [
    { field: "username", headerName: "User Name", width: 150 },
    { field: "amount", headerName: "Amount", width: 130 },
    { field: "wallet", headerName: "Wallet", width: 130 },
    { field: "set", headerName: "Set", width: 100 },
    { field: "task", headerName: "Task", width: 100 },
    {
      field: "state",
      headerName: "State",
      width: 130,
      renderCell: ({ row }) => {
        return (
          <Chip
            label={row.state}
            color={
              row.state === "PENDING"
                ? "warning"
                : row.state === "COMPLETED"
                ? "success"
                : "error"
            }
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      hideable: false,
      width: 500,
      renderCell: ({ row }) => {
        return (
          <div className="p-1 text-center">
            <Button
              variant="contained"
              color="warning"
              sx={{ margin: 1 }}
              onClick={() => {
                axios
                  .post("/api/admin/withdraw/ignore", { id: row.id })
                  .then((res) => {
                    if (res.data.success) {
                      dispatch({
                        type: MESSAGE_OPEN,
                        payload: {
                          type: "success",
                          text: "Create set successfully",
                        },
                      });
                      getUsers();
                    }
                  })
                  .catch((err) => {
                    dispatch({
                      type: MESSAGE_OPEN,
                      payload: {
                        type: "error",
                        text: err?.response?.data?.message
                          ? err.response.data.message
                          : "Server error",
                      },
                    });
                    if (err?.response?.data?.expired) navigate("/login");
                  });
              }}
            >
              Ignore
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ margin: 1 }}
              onClick={() => {
                axios
                  .post("/api/admin/withdraw/complete", { id: row.id })
                  .then((res) => {
                    if (res.data.success) {
                      dispatch({
                        type: MESSAGE_OPEN,
                        payload: {
                          type: "success",
                          text: "Create set successfully",
                        },
                      });
                      getUsers();
                    }
                  })
                  .catch((err) => {
                    dispatch({
                      type: MESSAGE_OPEN,
                      payload: {
                        type: "error",
                        text: err?.response?.data?.message
                          ? err.response.data.message
                          : "Server error",
                      },
                    });
                    if (err?.response?.data?.expired) navigate("/login");
                  });
              }}
            >
              Complete
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ margin: 1 }}
              onClick={() => {
                axios
                  .post("/api/admin/withdraw/delete", { id: row.id })
                  .then((res) => {
                    if (res.data.success) {
                      dispatch({
                        type: MESSAGE_OPEN,
                        payload: {
                          type: "success",
                          text: "Create set successfully",
                        },
                      });
                      getUsers();
                    }
                  })
                  .catch((err) => {
                    dispatch({
                      type: MESSAGE_OPEN,
                      payload: {
                        type: "error",
                        text: err?.response?.data?.message
                          ? err.response.data.message
                          : "Server error",
                      },
                    });
                    if (err?.response?.data?.expired) navigate("/login");
                  });
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-3">
        <div className="ml-3 text-lg font-bold ">User Management</div>
        <Button
          variant="contained"
          onClick={() => {
            setCreate(true);
          }}
        >
          Create Education Account
        </Button>
        <span className="mr-10">Invite Code: {invitecode}</span>
      </div>
      <DataGrid rows={rows} columns={columns} />

      <div className="mt-3 mb-3 ml-3 text-lg font-bold">
        Withdraw Management
      </div>
      <DataGrid rows={wrows} columns={wcolumns} />
      <AlertDialog
        open={deleteConfirm}
        handleClose={handleClose}
        handleOk={handleDelete}
        message={"Really delete this user?"}
      />
      <AlertDialog
        open={resetConfirm}
        handleClose={handleClose}
        handleOk={handleReset}
        message={"Really reset this set?"}
      />
      <NewSetDialog
        open={newSetConfirm}
        handleClose={handleClose}
        handleOk={handleNewSet}
        message={"Really make new set?"}
      />
      <DepositDialog
        open={depositConfirm}
        handleClose={handleClose}
        handleOk={handleDepoist}
        message={"Really make new set?"}
      />
      <CreateAccount
        open={create}
        handleOk={handleCreate}
        handleClose={handleClose}
      />
    </div>
  );
}

import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LOGIN_SUCCESS, LOADING } from "../redux/types";

import axios from "axios";
import { CircularProgress } from "@mui/material";

const PrivateAdminRoute = ({ children }) => {
  const role = useSelector((state) => state.auth.user.role);
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      axios
        .post("api/auth/jwt-login")
        .then((res) => {
          dispatch({ type: LOGIN_SUCCESS, payload: res.data.user });
        })
        .catch(() => {
          localStorage.removeItem("token");
          dispatch({ type: LOADING });
        });
    } else dispatch({ type: LOADING });
  }, []);

  if (loading) {
    return (
      <div className="p-24 mt-24 text-center">
        <div className="ml-56 mr-56">
          <CircularProgress />
        </div>
        <div>LOADING...</div>
      </div>
    );
  }

  return role === 2 ? children : <Navigate to="/login" />;
};

export default PrivateAdminRoute;

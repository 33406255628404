import { MESSAGE_OPEN, MESSAGE_CLOSE } from "../types";

const initialState = {
  text: "",
  open: false,
  type: "success",
  duration: 2000,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MESSAGE_OPEN:
      return {
        ...state,
        text: action.payload.text,
        type: action.payload.type,
        duration: action.payload.duration ? action.payload.duration : 2000,
        open: true,
      };
    case MESSAGE_CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}

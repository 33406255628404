import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField } from "@mui/material";

export default function CreateAccount({ handleClose, open, handleOk }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Make new set</DialogTitle>
      <DialogContent>
        <Box component={"form"} onSubmit={handleOk} noValidate={false}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="invitecode"
            name="invitecode"
            label="Invite Code"
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="invitename"
            name="invitename"
            label="Invite Name"
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="username"
            name="username"
            label="Education account name"
            fullWidth
            variant="outlined"
          />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained">
              Ok
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyledButton = styled(Button)({
  boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
  textTransform: "none",
  fontSize: 14,
  padding: "6px 12px",
  border: "none",
  borderRadius: "0px",
  lineHeight: 1.5,
  backgroundColor: "white",
  color: "rgb(10, 10, 10)",
  border: "none",
  "&:hover": {
    backgroundColor: "rgb(250, 250, 250)",
    border: "none",
  },
  "&:active": {
    backgroundColor: "rgb(250, 250, 250)",
    border: "none",
  },
  "&:focus": {
    backgroundColor: "rgb(250, 250, 250)",
    border: "none",
  },
});

export default StyledButton;
